import { useEditorStore } from "@/stores/editor";
import { $generateNodesFromDOM } from "@lexical/html";
import { $createLinkNode } from "@lexical/link";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, HeadingTagType } from "@lexical/rich-text";
import { $isTableNode } from "@lexical/table";
import {
  $createLineBreakNode,
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isElementNode,
  $setSelection,
} from "lexical";
import { marked } from "marked";
import extendedTables from "marked-extended-tables";
import { useCallback, useState } from "react";
import { PLAYGROUND_TRANSFORMERS } from "../../../components/AdvancedEditor/plugins/MarkdownTransformers";
import { getHostname } from "./url";
marked.use(extendedTables());

const IGNORE_TAGS = new Set(["STYLE", "SCRIPT"]);

export const useHandleTransformNodes = () => {
  const { editor } = useEditorStore();
  const lexicalEditorInstance = editor.instance;

  const handleTransformNodes = useCallback(
    async (generatedNodeKeys: string[], setLastSelection) => {
      lexicalEditorInstance?.update(() => {
        const nodes = generatedNodeKeys.map((nodeKey) => {
          const node = $getNodeByKey(nodeKey);
          return node;
        });

        nodes.forEach((node) => {
          if ($isElementNode(node)) {
            $convertFromMarkdownString(
              node.getTextContent(),
              PLAYGROUND_TRANSFORMERS,
              node
            );
          }
        });

        const selection = $getSelection();
        const selectedNodes = selection?.getNodes();
        let lastNode = selectedNodes?.[selectedNodes.length - 1];

        while (lastNode && lastNode.getParent()) {
          if ($isTableNode(lastNode)) {
            lastNode.append($createParagraphNode());
            break;
          }
          if (lastNode.getParent().getKey() === "root") break;
          lastNode = lastNode.getParent() || lastNode;
        }

        const newSelection = lastNode?.selectEnd();
        setLastSelection(newSelection);
      });
    },
    [lexicalEditorInstance]
  );

  return handleTransformNodes;
};

function preprocessMarkdown(markdownText: string): string {
  return markdownText
    .split("\n")
    .map((line) => line.trimStart())
    .join("\n");
}

export const useHandleInsertMarkdown = () => {
  const [editor] = useLexicalComposerContext();
  const [isInserting, setIsInserting] = useState<boolean>(false);

  const handleOnInsert = useCallback(
    async (markdownText: string, shouldInsertParagraph: boolean) => {
      if (isInserting) return;
      setIsInserting(true);
      let insertedNodeKeys: string[] = []; // Array to store inserted node keys
      let insertedTableNodeKeys: string[] = []; // Array to store inserted table keys
      let insertedNodeText: string[] = []; // Array to store inserted node text
      let insertedLastSelection = null;

      await editor?.update(() => {
        let selection = $getSelection();

        if (!selection) {
          editor.focus();
          selection = $getSelection();
        }

        if (/^\|.*\|$/.test(markdownText.trim())) {
          selection?.insertRawText(markdownText);
          insertedNodeKeys.push(
            ...selection?.getNodes().map((node) => node.getParent().getKey())
          );
          insertedTableNodeKeys.push(
            ...selection?.getNodes().map((node) => node.getParent().getKey())
          );
        } else {
          // Custom tokenizer to ignore code blocks and specific patterns
          const tokenizer = {
            code(src) {
              return null;
            },
            fences(src) {
              const match = /^(```)\n?([\s\S]*?)\n?(```)/.exec(src);
              if (match) {
                return {
                  type: "paragraph",
                  raw: match[0],
                  text: match[0],
                  tokens: [{ type: "text", raw: match[2], text: match[2] }],
                };
              }
              // Return undefined to let marked handle other fences normally
              return undefined;
            },
          };
          marked.use({ tokenizer });

          const preprocessedMarkdownText = preprocessMarkdown(markdownText);
          const htmlString = marked.parse(preprocessedMarkdownText);
          const doc = new DOMParser().parseFromString(htmlString, "text/html");

          let nodes = $generateNodesFromDOM(editor, doc);
          insertedNodeText.push(...nodes.map((node) => node.getTextContent()));
          insertedNodeKeys.push(...nodes.map((node) => node.getKey()));

          if (shouldInsertParagraph) {
            nodes = [...nodes, $createParagraphNode()];
          }

          $insertNodes(nodes);
        }
      });

      setIsInserting(false);
      return {
        insertedNodeKeys,
        insertedTableNodeKeys,
        insertedNodeText,
        insertedLastSelection,
      };
    },
    [editor, isInserting]
  );

  return handleOnInsert;
};

export const useHandlePasteContent = () => {
  const { editor, setEditor } = useEditorStore();
  const lexicalEditorInstance = editor.instance;
  const [isPasting, setIsPasting] = useState<boolean>(false); // new state to prevent repeated pasting

  const handlePasteContent = useCallback(
    async (contentInput: string | string[]) => {
      if (isPasting) return;
      setIsPasting(true);
      await setEditor((state) => {
        state.isPasting = true;
      });

      const contentArray = Array.isArray(contentInput)
        ? contentInput
        : [contentInput];

      lexicalEditorInstance?.update(() => {
        contentArray.forEach((content) => {
          const selection = $getSelection();
          if (!selection) {
            $setSelection($getRoot().getLastChild()?.select());
          }
          let nodes = [];

          const textNode = $createTextNode(content);
          const paragraphNode = $createParagraphNode();
          paragraphNode.append(textNode);

          const selectionNodes = selection?.getNodes();
          const selectionHasContent = selectionNodes?.some((node) => {
            return node.getTextContent() !== "";
          });
          if (
            selectionNodes &&
            selectionNodes.length > 0 &&
            selectionHasContent
          ) {
            nodes.push($createLineBreakNode());
          }

          nodes.push(paragraphNode);
          nodes.push($createParagraphNode());
          $insertNodes(nodes);
        });
      });

      setIsPasting(false);
    },
    [lexicalEditorInstance]
  );

  return handlePasteContent;
};

export const useHandlePasteCitation = () => {
  const { editor, setEditor } = useEditorStore();
  const lexicalEditorInstance = editor.instance;
  const [isPasting, setIsPasting] = useState<boolean>(false); // new state to prevent repeated pasting

  const handlePasteContent = useCallback(
    async (url: string) => {
      if (isPasting) return;
      setIsPasting(true);
      await setEditor((state) => {
        state.isPasting = true;
      });

      lexicalEditorInstance?.update(() => {
        const linkNode = $createLinkNode(url, { title: url });
        const sourceTextNode = $createTextNode("Source: ");
        const linkTextNode = $createTextNode(getHostname(url));
        const paragraphNode = $createParagraphNode();
        linkNode.append(linkTextNode);
        paragraphNode.append(sourceTextNode);
        paragraphNode.append(linkNode);
        $insertNodes([paragraphNode, $createParagraphNode()]);
      });

      setIsPasting(false);
    },
    [lexicalEditorInstance]
  );

  return handlePasteContent;
};

export const useHandlePasteInlineCitation = () => {
  const { editor, setEditor } = useEditorStore();
  const lexicalEditorInstance = editor.instance;
  const [isPasting, setIsPasting] = useState<boolean>(false);

  const handlePasteInlineCitation = useCallback(
    async (
      firstPart: string,
      secondPart: string,
      url: string,
      thirdPart: string
    ) => {
      if (isPasting) return;
      setIsPasting(true);
      await setEditor((state) => {
        state.isPasting = true;
      });

      lexicalEditorInstance?.update(() => {
        let selection = $getSelection();
        if (!selection) {
          editor.instance?.focus();
          selection = $getSelection();
        }

        if (selection) {
          const textNodeFirstPart = $createTextNode(firstPart);
          const linkNode = $createLinkNode(url);
          const textNodeSecondPart = $createTextNode(secondPart);
          const textNodeThirdPart = $createTextNode(thirdPart);

          linkNode.append(textNodeSecondPart);
          selection.insertNodes([
            textNodeFirstPart,
            linkNode,
            textNodeThirdPart,
            $createParagraphNode(),
          ]);
        }
      });

      setIsPasting(false);
    },
    [lexicalEditorInstance, isPasting]
  );

  return handlePasteInlineCitation;
};

interface Heading {
  header: string;
  user_header_tag: HeadingTagType;
  url: string;
}

export const useHandlePasteHeadings = () => {
  const { editor, setEditor } = useEditorStore();
  const lexicalEditorInstance = editor.instance;
  const [isPasting, setIsPasting] = useState(false);

  const handlePasteContent = useCallback(
    async (contentInput: Heading[]) => {
      if (isPasting) return; // Prevents paste operation if already pasting is in progress
      setIsPasting(true); // set state to true as the pasting started
      await setEditor((state) => {
        state.isPasting = true;
      });

      // First, we collect all nodes
      lexicalEditorInstance?.update(() => {
        let selection = $getSelection();
        if (!selection) {
          editor.instance?.focus();
          selection = $getSelection();
        }
        const nodes = [];

        // First, we collect all nodes
        const selectionNodes = selection?.getNodes();
        const selectionHasContent = selectionNodes?.some((node) => {
          return node.getTextContent() !== "";
        });
        if (
          selectionNodes &&
          selectionNodes.length > 0 &&
          selectionHasContent
        ) {
          nodes.push($createLineBreakNode());
        }

        const allNodes = contentInput.flatMap((content) => {
          const headerNode = $createHeadingNode(content.user_header_tag);
          const headerTextNode = $createTextNode(content.header);
          headerNode.append(headerTextNode);

          return [headerNode];
        });
        // Then, we insert all nodes at once
        if (selection) {
          selection.insertNodes([
            ...nodes,
            ...allNodes,
            $createParagraphNode(),
          ]);
        } else {
          $insertNodes([...nodes, ...allNodes, $createParagraphNode()]);
        }
      });

      setIsPasting(false); // reset state once the pasting operation is done
    },
    [lexicalEditorInstance]
  );

  return handlePasteContent;
};
