import { Location, useLocation, useRoutes } from "react-router-dom";

import { Landing, NotFound } from "@/features/misc";
import { useAuth } from "@/lib/auth";

import { OnboardingNewDocument } from "@/features/onboarding/routes/OnboardingNewDocument";
import { OnboardingSurvey } from "../features/onboarding/routes/OnboardingSurvey";
import { ConfirmVerification } from "../features/verification/routes/ConfirmVerification";
import { VerifyEmail } from "../features/verification/routes/VerifyEmail";
import storage from "../utils/storage";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

function trackLastVisitedPath(auth: any, location: Location) {
  if (!auth.user && location.pathname.startsWith("/app")) {
    storage.setLastVisitedPath(
      location.pathname + location.search + location.hash
    );
  }
}

export const AppRoutes = () => {
  const auth = useAuth();
  const location = useLocation();

  trackLastVisitedPath(auth, location);

  const commonRoutes = [
    {
      path: "/",
      element: <Landing />,
    },
    { path: "/app/verify-email", element: <VerifyEmail /> },
    { path: "/app/verification/:token", element: <ConfirmVerification /> },
    { path: "/app/onboarding", element: <OnboardingSurvey /> },
    {
      path: "/app/new-document",
      element: <OnboardingNewDocument />,
    },
    { path: "*", element: <NotFound /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
