// src/features/onboarding/api/updateOnboardStatus.ts
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { axios } from "../../../lib/axios";
import { MutationConfig } from "../../../lib/react-query";

export const updateOnboardStatus = (): Promise<void> => {
  return axios.post("/updateOnboardStatus", {});
};

type UseUpdateOnboardStatusOptions = {
  config?: MutationConfig<typeof updateOnboardStatus>;
};

export const useUpdateOnboardStatus = ({
  config,
}: UseUpdateOnboardStatusOptions = {}) => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: updateOnboardStatus,
    onSuccess: () => {
      navigate("/app/new-document");
    },
    ...config,
  });
};
