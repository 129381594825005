import { Button, Label, Textarea } from "@/components/Elements";
import { ShortcutTag } from "@/components/Elements/Tag/ShortcutTag";
import { useDeleteBookmarkedTemplate } from "@/features/ai-tools/api/deleteAiToolBookmark";
import { useUpdateAiToolBookmark } from "@/features/ai-tools/api/updateAiToolBookmark";
import {
  ToolDescription,
  ToolTutorial,
} from "@/features/ai-tools/routes/AiTool";
import { useGetGptTemplate } from "@/features/ai/api/getGptTemplate";
import { cn } from "@/utils/style";
import { IS_APPLE } from "@lexical/utils";
import { useRef, useState } from "react";
import {
  TbArrowLeft,
  TbBookmark,
  TbBookmarkFilled,
  TbSparkles,
  TbX,
} from "react-icons/tb";

const ToolHeader = ({ title, onBack, onClose, onBookmark, isBookmarked }) => (
  <div className="flex justify-between py-2 px-2 border-b bg-white dark:bg-zinc-800 dark:border-b-zinc-700 rounded-t-md">
    <div className="flex items-center space-x-2">
      <Button
        onClick={onBack}
        variant="buttonIcon"
        buttonIcon={<TbArrowLeft />}
        tooltipContent="Return to AI writer"
      ></Button>
      <h1 className="font-medium text-sm dark:text-white">{title}</h1>
    </div>
    <div className="flex items-center space-x-1">
      <Button
        variant="buttonNodeIcon"
        size="sm"
        onClick={onBookmark}
        buttonIcon={isBookmarked ? <TbBookmarkFilled /> : <TbBookmark />}
        className={cn(
          "flex-shrink-0",
          isBookmarked
            ? "text-emerald-600 fill-emerald-600 bg-emerald-600 visible"
            : "text-zinc-500 hover:text-zinc-900"
        )}
        tooltipContent={isBookmarked ? "Remove bookmark" : "Bookmark tool"}
      ></Button>
      <Button
        onClick={onClose}
        variant="buttonIcon"
        buttonIcon={<TbX />}
        tooltipContent="Close AI writer"
      ></Button>
    </div>
  </div>
);

export function AiToolPanel({
  template,
  onClose,
  onBack,
  onGenerateComplete,
  templateFieldsData,
  setTemplateFieldsData,
  refetchTemplates,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedVariants, setSelectedVariants] = useState("1");
  const [isLoadingOutput, setIsLoadingOutput] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(template.isBookmarked);
  const updateBookmark = useUpdateAiToolBookmark();
  const abortControllerRef = useRef<AbortController | null>(null);
  const deleteBookmark = useDeleteBookmarkedTemplate();

  const { refetch: getTemplateOutput } = useGetGptTemplate({
    template_hash: template?.hash,
    prompt_map: templateFieldsData,
    num_return_sequences: Number(selectedVariants),
    lang: selectedLanguage,
    config: {
      enabled: false,
    },
  });

  // Extract variables from template_prompt
  const prompt = template?.metadata?.template_prompt || "";
  const variableRegex = /%([^%]+)%/g;
  let match;

  while ((match = variableRegex.exec(prompt)) !== null) {
    const variableName = match[1];
    if (!(variableName in templateFieldsData)) {
      setTemplateFieldsData((prev) => ({ ...prev, [variableName]: "" }));
    }
  }

  const handleFieldChange = (fieldName: string, value: string) => {
    setTemplateFieldsData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const allFieldsFilled = Object.values(templateFieldsData).every(
    (value) => value.trim() !== ""
  );

  const handleGenerateClick = () => {
    setIsLoadingOutput(true);
    abortControllerRef.current = new AbortController();
    getTemplateOutput({ signal: abortControllerRef.current.signal })
      .then((response) => {
        if (response) {
          onGenerateComplete(response.data?.gpt_generations[0].generation);
        }
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error generating template output:", error);
        }
      })
      .finally(() => {
        setIsLoadingOutput(false);
      });
  };

  const handlePanelKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      (event.metaKey || event.ctrlKey) &&
      allFieldsFilled
    ) {
      handleGenerateClick();
    }
  };

  const handleBookmarkClick = () => {
    setIsBookmarked((prev) => !prev);
    if (isBookmarked) {
      deleteBookmark.mutate(template?.hash!, {
        onError: () => {
          setIsBookmarked(true);
        },
        onSuccess: () => {
          refetchTemplates();
        },
      });
    } else {
      updateBookmark.mutate(template?.hash!, {
        onError: () => {
          setIsBookmarked(false);
        },
        onSuccess: () => {
          refetchTemplates();
        },
      });
    }
  };

  const handleBack = () => {
    if (isLoadingOutput && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onBack();
  };

  const handleClose = () => {
    if (isLoadingOutput && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onClose();
  };

  const fields = Object.keys(templateFieldsData).map((variableName, index) => (
    <div className="flex flex-col">
      <Label className="mb-2">
        {variableName[0].toUpperCase() + variableName.slice(1)}
      </Label>
      <Textarea
        id={variableName}
        placeholder={`Input ${variableName}...`}
        value={templateFieldsData[variableName]}
        onChange={(e) => handleFieldChange(variableName, e.target.value)}
        className="max-h-40"
        autoFocus={index === 0}
        onKeyDown={handlePanelKeyDown}
      />
      <p className="mt-1 text-xs text-zinc-500">
        {templateFieldsData[variableName].split(" ").filter(Boolean).length}{" "}
        words
      </p>
    </div>
  ));

  return (
    <div
      className="flex flex-col w-full mb-40"
      tabIndex={0}
      onKeyDown={handlePanelKeyDown}
    >
      <ToolHeader
        title={template?.text?.[0]?.title || "AI Tool"}
        onBack={handleBack}
        onClose={handleClose}
        onBookmark={handleBookmarkClick}
        isBookmarked={isBookmarked}
      />
      <div className="flex flex-col space-y-4 w-full h-fit p-6 bg-zinc-50 dark:bg-zinc-900 overflow-y-scroll rounded-md">
        {template?.metadata?.template_description && (
          <div className="flex flex-col space-y-1">
            <ToolDescription
              description={
                template?.metadata.template_description || "No description."
              }
              isLoadingTool={false}
            />
            {template?.metadata?.template_tutorial && (
              <ToolTutorial
                tutorial={
                  template?.metadata.template_tutorial || "No tutorial."
                }
                isLoadingTool={false}
              />
            )}
          </div>
        )}
        {fields}
        <Button
          variant="primary"
          className="flex-shrink-0"
          startIcon={
            <TbSparkles className="w-4 h-4 mr-1 fill-white dark:fill-emerald-400" />
          }
          onClick={handleGenerateClick}
          isLoading={isLoadingOutput}
          disabled={!allFieldsFilled}
          tooltipModifierKey={IS_APPLE ? "⌘+Enter" : "Ctrl+Enter"}
        >
          {isLoadingOutput ? (
            "Generating..."
          ) : (
            <div className="flex items-center space-x-2">
              <span>Generate</span>
              <div className="flex items-center space-x-1">
                <ShortcutTag className="text-zinc-700 font-normal dark:text-zinc-300">
                  {IS_APPLE ? "⌘+Enter" : "Ctrl+Enter"}
                </ShortcutTag>
              </div>
            </div>
          )}
        </Button>
      </div>
    </div>
  );
}
