import { Button } from "@/components/Elements";
import { cn } from "@/utils/style";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isAtNodeEnd } from "@lexical/selection";
import { $getSelection, $isTextNode, KEY_ENTER_COMMAND } from "lexical";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { IS_APPLE } from "../../shared/environment";
import { getDOMRangeRect } from "../../utils/getDOMRangeRect";
import { setFloatingElemPosition } from "../../utils/setFloatingElemPosition";

const ContinueWritingPlugin = ({
  anchorElem = document.body,
  showAiWriter,
}: {
  anchorElem?: HTMLElement;
  showAiWriter: boolean;
}) => {
  const [editor] = useLexicalComposerContext();
  const continueWritingRef = useRef<HTMLDivElement | null>(null);

  const [isVisible, setIsVisible] = useState(false);

  const setOpacity = (element: HTMLDivElement | null, opacity: string) => {
    element?.style.setProperty("opacity", opacity);
  };

  const setContinueWritingVisibility = (isVisible: boolean) => {
    const continueWritingElement = continueWritingRef.current;
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const rootElement = editor.getRootElement();

    if (
      selection &&
      nativeSelection &&
      nativeSelection.rangeCount > 0 &&
      rootElement &&
      continueWritingElement
    ) {
      const points = selection.getStartEndPoints();
      if (points) {
        const [anchor] = points;
        const node = anchor.getNode();
        if (
          $isTextNode(node) &&
          node.isSimpleText() &&
          $isAtNodeEnd(anchor) &&
          node.getNextSibling() === null &&
          node.getTextContent() !== "/" &&
          showAiWriter === false
        ) {
          if (isVisible) {
            const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
            setFloatingElemPosition(
              rangeRect,
              continueWritingElement,
              anchorElem,
              -(rangeRect.height / 2) - 14,
              -4
            );
          }
          setOpacity(continueWritingElement, isVisible ? "1" : "0");
          setIsVisible(isVisible);
          return;
        } else {
          setOpacity(continueWritingElement, "0");
          setIsVisible(false);
          setFloatingElemPosition(
            {
              top: -1000,
              left: -1000,
              width: 0,
              height: 0,
              bottom: 0,
              right: 0,
              x: -1000,
              y: -1000,
              toJSON: () => {},
            },
            continueWritingElement,
            anchorElem,
            0,
            0
          );
        }
      }
    }
    setOpacity(continueWritingElement, "0");
    setIsVisible(false);
  };

  const debouncedShowContinueWriting = debounce(() => {
    editor.update(() => {
      setContinueWritingVisibility(true);
    });
  }, 750);

  const hideContinueWriting = () => {
    editor.update(() => {
      setContinueWritingVisibility(false);
      setIsVisible(false);
    });
  };

  editor.registerUpdateListener(() => {
    debouncedShowContinueWriting();
    hideContinueWriting();
  });

  editor.registerTextContentListener(() => {
    setOpacity(continueWritingRef.current, "0");
    setIsVisible(false);
  });

  return createPortal(
    <Button
      variant="buttonIcon"
      ref={continueWritingRef}
      className={cn(
        "absolute top-0 left-0 ml-[2px] stroke-zinc-300 hover:stroke-zinc-400 transition-opacity duration-500 opacity-0 dark:stroke-zinc-700 dark:hover:stroke-zinc-500 dark:hover:bg-zinc-800",
        isVisible
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      )}
      onClick={() => {
        editor.update(() => {
          // Create a synthetic KeyboardEvent with the MetaKey or CtrlKey pressed
          const syntheticEvent = new KeyboardEvent("keydown", {
            key: "Enter",
            metaKey: IS_APPLE,
            ctrlKey: !IS_APPLE,
          });

          editor.dispatchCommand(KEY_ENTER_COMMAND, syntheticEvent);
        });
        setIsVisible(false);
      }}
      tooltipContent="Continue Writing"
      tooltipModifierKey={IS_APPLE ? "⌘ + Enter" : "Ctrl + Enter"}
      tooltipDirection="top"
      tooltipDelay={0}
      aria-label="Continue Writing"
      buttonIcon={
        <ArrowRightIcon
          style={{
            strokeWidth: 1.5,
            color:
              continueWritingRef.current?.style.getPropertyValue("opacity") ===
              "1"
                ? "currentColor"
                : "transparent",
          }}
          className={cn("h-2.5 w-2.5 text-zinc-400 hover:text-zinc-800")}
        />
      }
      size="md"
    ></Button>,
    anchorElem
  );
};

export default ContinueWritingPlugin;
