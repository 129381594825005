import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
  Textarea,
} from "@/components/Elements";
import { Button } from "@/components/Elements/Button";
import { Input } from "@/components/Elements/Input";
import { useState } from "react";
import { TbFilePlus } from "react-icons/tb";

export function CreateDocumentFromOutputDialog({
  generation,
  promptMap,
  onCreate,
}) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = async () => {
    setIsLoading(true);
    const formattedGeneration = generation
      .split("\n")
      .filter((line) => line.trim() !== "")
      .map((line) => `<p>${line}</p>`)
      .join("");
    await onCreate(title, formattedGeneration, promptMap);
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="buttonIcon"
          buttonIcon={<TbFilePlus />}
          tooltipContent="Create document from output"
        />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>Create document from output</DialogTitle>
          <DialogCloseButton close={() => setOpen(false)} />
        </DialogHeader>
        <DialogDescription className="px-4 space-y-2">
          <div className="flex flex-col space-y-1">
            <Label>Document title</Label>
            <Input
              autoFocus
              value={title}
              onChange={(value) => setTitle(value)}
              placeholder="Enter a document title..."
              className="mb-4"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <Label>Content preview</Label>
            <Textarea
              value={generation}
              readOnly
              className="mb-4 max-h-[calc(100vh-24rem)]"
            />
          </div>
        </DialogDescription>
        <DialogFooter className="px-4 pb-4">
          <Button variant="outlineBlur" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="primaryBlur"
            onClick={handleCreate}
            isLoading={isLoading}
            disabled={!title}
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
