import { languageOptions } from "@/components/Elements/Dialog/newDocumentOptions";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useUser } from "@/features/auth";
import { useCreateDocument } from "@/features/documents/api/createDocument";
import { useNotificationStore } from "@/stores/notifications";
import { useNavigate } from "react-router-dom";

export const useCreateNewDocumentWithUser = () => {
  const createDocumentMutation = useCreateDocument();
  const { addNotification } = useNotificationStore();
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();
  const { data: user } = useUser();
  const userGeography = JSON.parse(user?.geography || "{}");

  const createNewDocument = async (
    title: string,
    htmlContent: string,
    data: any
  ) => {
    if (!user) {
      addNotification({
        title: "User not found",
        type: "error",
      });
      return;
    }

    try {
      const res = await createDocumentMutation.mutateAsync({
        doc_owner: user.id,
        doc_owner_name: user.fullName,
        doc_status: 1,
        metadata: {
          name: title,
          code: userGeography.code || "us",
          display_code: userGeography.display_code || "United States",
          lang_code: userGeography.lang || "en",
          lang_display:
            languageOptions.find(
              (language) => language.value === userGeography.lang
            )?.label || "English",
          url: null,
        },
        org_id: user.orgId,
        query: "",
        template: null,
        text: [
          {
            html: htmlContent,
            title: title,
            name: "Tab 1",
          },
        ],
        title: title,
      });

      trackEvent.mutate({
        event: "new_document",
        properties: JSON.stringify({ type: "blank" }),
      });

      navigate(`/app/documents/${res?.hash}`);
    } catch (error) {
      console.error("error", error);
      addNotification({
        title: "Error creating document",
        type: "error",
      });
    }
  };

  return { createNewDocument };
};
