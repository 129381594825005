import { Navigate, Route, Routes } from "react-router-dom";

import { Document } from "./Document";
import { Documents } from "./Documents";

export const DocumentsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Documents />} />
      <Route path=":documentHash" element={<Document />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
