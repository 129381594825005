import { LoadSpinner } from "@/components/AdvancedEditor/plugins/ResearchPlugin/SerpPlaceholder";
import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "@/components/Elements";
import { Skeleton } from "@/components/Elements/Skeleton";
import {
  GptTemplateResponse,
  useGetGptTemplate,
} from "@/features/ai/api/getGptTemplate";
import { LanguageSelect } from "@/features/ai/components/LanguageSelect";
import { useUser } from "@/features/auth";
import { useNotificationStore } from "@/stores/notifications";
import { useEffect, useRef, useState } from "react";
import {
  TbCopy,
  TbSparkles,
  TbThumbDownFilled,
  TbThumbUpFilled,
  TbTrash,
} from "react-icons/tb";
import { useLocation, useParams } from "react-router-dom";
import { useUpdateAiToolMetrics } from "../api/updateAiToolMetrics";
import { useUpdateAiToolRating } from "../api/updateAiToolRating";

import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useCreateNewDocumentWithUser } from "@/hooks/useCreateDocumentWithUser";
import { cn } from "@/utils/style";
import dayjs from "dayjs";
import _ from "lodash";
import { useDeleteAiToolOutput } from "../api/deleteAiToolOutput";
import { useGetAiToolByHash } from "../api/getAiToolByHash";
import { TemplateOutput, useGetAiToolOutputs } from "../api/getAiToolOutputs";
import { useUpdateAiToolOutputs } from "../api/updateAiToolOutputs";
import { useUpdateAiToolVote } from "../api/updateAiToolVote";
import { AiToolHeader } from "../components/AiToolHeader";
import { AiToolOutputHeader } from "../components/AiToolOutputHeader";
import { CreateDocumentFromOutputDialog } from "../components/CreateDocumentFromOutputDialog";

function AiTool() {
  const params = useParams<{ aiToolHash: string }>();
  const location = useLocation();
  const trackEvent = useTrackEvent();
  const aiToolHash = params.aiToolHash;
  const { data: user, isLoading: isLoadingUser } = useUser();
  const { data: aiTool, isLoading: isAiToolLoading } = useGetAiToolByHash({
    hash: aiToolHash!,
  });
  const userGeography = JSON.parse(user?.geography || "{}");
  const [selectedLanguage, setSelectedLanguage] = useState(
    userGeography?.lang || "en"
  );
  const [selectedVariants, setSelectedVariants] = useState("1");
  const [activeOutputTab, setActiveOutputTab] = useState("output");
  const [outputs, setOutputs] = useState<GptTemplateResponse[]>([]);
  const [outputHistory, setOutputHistory] = useState<TemplateOutput[]>([]);
  const [fieldsData, setFieldsData] = useState({});
  const [selectedVotes, setSelectedVotes] = useState<{
    [key: string]: "upvote_count" | "downvote_count" | null;
  }>({});

  const [isLoadingGptTemplate, setIsLoadingGptTemplate] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(
    location.state?.isBookmarked || false
  );
  const aiToolId = aiTool?.id;
  const isOwner = aiTool?.org_id === user?.orgId;
  const isEditMode = location.pathname.includes("/new");
  const outputEndRef = useRef<HTMLDivElement>(null);
  const updateVote = useUpdateAiToolVote();
  const loadingSpinnerRef = useRef<HTMLDivElement>(null);

  const handleVote = (
    aiToolId: string,
    voteType: "upvote_count" | "downvote_count"
  ) => {
    setSelectedVotes((prev) => ({ ...prev, [aiToolId]: voteType }));
    updateVote.mutate({
      template_hash: aiToolHash!,
      field_name: voteType,
    });
    setOutputs((prev) =>
      prev.map((output) =>
        output.id === aiToolId
          ? { ...output, rdating: voteType === "upvote_count" ? 1 : -1 }
          : output
      )
    );
    setOutputHistory((prev) =>
      prev.map((output) =>
        output.id === aiToolId
          ? { ...output, rating: voteType === "upvote_count" ? 1 : -1 }
          : output
      )
    );
  };

  const { refetch: getTemplateOutput } = useGetGptTemplate({
    template_hash: aiToolHash!,
    prompt_map: fieldsData,
    num_return_sequences: Number(selectedVariants),
    lang: selectedLanguage,
    config: {
      enabled: false,
    },
  });

  const updateAiToolMetrics = useUpdateAiToolMetrics();
  const updateAiToolOutputs = useUpdateAiToolOutputs();
  const { refetch: getAiToolOutputs } = useGetAiToolOutputs({
    template_hash: aiToolHash!,
    config: {
      enabled: false,
    },
  });

  useEffect(() => {
    getAiToolOutputs().then((response) => {
      setOutputHistory(response.data!);
    });
  }, [getAiToolOutputs]);

  const handleTrackRunAiTool = (name: string, hash: string) => {
    trackEvent.mutate({
      event: "run_ai_tool",
      properties: JSON.stringify({
        name: name,
        hash: hash,
      }),
    });
  };

  const handleGenerateClick = () => {
    setIsLoadingGptTemplate(true);
    if (loadingSpinnerRef.current) {
      loadingSpinnerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    const timestamp = Math.floor(Date.now() / 1000);
    getTemplateOutput()
      .then((response) => {
        if (response.data) {
          const newOutputs = response.data.gpt_generations.map(
            (generation) => ({
              ...response.data,
              generation: generation.generation,
              unix_timestamp: timestamp,
              fieldsData: { ...fieldsData },
              rating: 0,
            })
          );

          setOutputs((prev) => [...prev, ...newOutputs]);

          const newOutputHistory = newOutputs.map((output) => ({
            id: "",
            user_hash: user?.hash || "",
            template_hash: aiToolHash!,
            generation: output.generation,
            prompt_map: fieldsData,
            unix_timestamp: timestamp,
            rating: 0,
          }));

          setOutputHistory((prev) => [
            ...prev,
            ...newOutputHistory.sort(
              (a, b) => b.unix_timestamp - a.unix_timestamp
            ),
          ]);
        }
        if (outputEndRef.current) {
          outputEndRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
        handleTrackRunAiTool(
          aiTool?.text?.[0]?.title || "AI Tool",
          aiToolHash!
        );

        updateAiToolMetrics
          .mutateAsync({
            template_hash: aiToolHash!,
            field_name: "hit_count",
          })
          .then(() => {
            response.data?.gpt_generations.forEach((gen, index) => {
              updateAiToolOutputs
                .mutateAsync({
                  template_hash: aiToolHash!,
                  generation: gen.generation,
                  prompt_map: fieldsData,
                })
                .then((ids) => {
                  // update outputs with the ids
                  setOutputs((prev) =>
                    prev.map((output, idx) =>
                      idx < prev.length - response.data.gpt_generations.length
                        ? output
                        : {
                            ...output,
                            id: ids[
                              idx -
                                (prev.length -
                                  response.data.gpt_generations.length)
                            ],
                          }
                    )
                  );
                  setOutputHistory((prev) =>
                    prev
                      .map((output, idx) =>
                        idx < prev.length - response.data.gpt_generations.length
                          ? output
                          : {
                              ...output,
                              id: ids[
                                idx -
                                  (prev.length -
                                    response.data.gpt_generations.length)
                              ],
                            }
                      )
                      .sort((a, b) => b.unix_timestamp - a.unix_timestamp)
                  );
                });
            });
          });
      })
      .catch((error) => {
        console.error("Error generating template output:", error);
      })
      .finally(() => {
        setIsLoadingGptTemplate(false);
      });
  };

  useEffect(() => {
    if (outputEndRef.current && !isLoadingGptTemplate) {
      outputEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [outputs]);

  useEffect(() => {
    if (loadingSpinnerRef.current && isLoadingGptTemplate) {
      loadingSpinnerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [isLoadingGptTemplate]);

  return (
    <div className="flex h-screen">
      <ToolConfiguration
        aiTool={aiTool}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        selectedVariants={selectedVariants}
        setSelectedVariants={setSelectedVariants}
        fieldsData={fieldsData}
        setFieldsData={setFieldsData}
        onGenerate={handleGenerateClick}
        isLoadingOutput={isLoadingGptTemplate}
        isLoadingTool={isAiToolLoading || isLoadingUser}
        isBookmarked={isBookmarked}
        aiToolHash={aiToolHash}
        aiToolId={aiToolId}
        user={user}
        isOwner={isOwner}
        isEditMode={isEditMode}
      />
      {activeOutputTab === "output" ? (
        <ToolOutput
          activeOutputTab={activeOutputTab}
          setActiveOutputTab={setActiveOutputTab}
          outputs={outputs}
          outputHistory={outputHistory}
          onGenerate={handleGenerateClick}
          isLoadingOutput={isLoadingGptTemplate}
          aiToolHash={aiToolHash}
          outputHistoryTotal={outputHistory?.length}
          setOutputs={setOutputs}
          setOutputHistory={setOutputHistory}
          fieldsData={fieldsData}
          outputEndRef={outputEndRef}
          loadingSpinnerRef={loadingSpinnerRef}
          selectedVotes={selectedVotes}
          handleVote={handleVote}
        />
      ) : (
        <ToolHistory
          isLoadingOutput={isLoadingGptTemplate}
          activeOutputTab={activeOutputTab}
          setActiveOutputTab={setActiveOutputTab}
          outputs={outputs}
          outputHistory={outputHistory}
          outputTotal={outputs?.length}
          setOutputs={setOutputs}
          setOutputHistory={setOutputHistory}
          handleVote={handleVote}
        />
      )}
    </div>
  );
}

export function ToolConfiguration({
  aiTool,
  selectedLanguage,
  setSelectedLanguage,
  selectedVariants,
  setSelectedVariants,
  fieldsData,
  setFieldsData,
  onGenerate,
  isLoadingTool,
  isLoadingOutput,
  isBookmarked,
  aiToolHash,
  aiToolId,
  user,
  isOwner,
  isEditMode,
}) {
  // Extract variables from template_prompt
  const prompt = aiTool?.metadata?.template_prompt || "";
  const variableRegex = /%([^%]+)%/g;
  let match;

  while ((match = variableRegex.exec(prompt)) !== null) {
    const variableName = match[1];
    if (!(variableName in fieldsData)) {
      setFieldsData((prev) => ({ ...prev, [variableName]: "" }));
    }
  }

  const handleFieldChange = (fieldName: string, value: string) => {
    setFieldsData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const allFieldsFilled = Object.values(fieldsData).every(
    (value) => value.trim() !== ""
  );

  const fields = isLoadingTool ? (
    <>
      <Skeleton className="h-6 mb-2" />
      <Skeleton className="h-10 mb-4" />
      <Skeleton className="h-6 mb-2" />
      <Skeleton className="h-10 mb-4" />
    </>
  ) : (
    Object.keys(fieldsData).map((variableName) => (
      <div className="flex flex-col">
        <Label className="mb-2">
          {variableName[0].toUpperCase() + variableName.slice(1)}
        </Label>
        <Textarea
          id={variableName}
          placeholder={`Input ${variableName}...`}
          value={fieldsData[variableName]}
          onChange={(e) => handleFieldChange(variableName, e.target.value)}
          className="max-h-40"
        />
        <p className="mt-1 text-xs text-zinc-500">
          {fieldsData[variableName].split(" ").filter(Boolean).length} words
        </p>
      </div>
    ))
  );

  return (
    <div className="flex flex-col w-1/3 border-r dark:border-zinc-700">
      <AiToolHeader
        title={aiTool?.text?.[0]?.title || "AI Tool"}
        isBookmarked={isBookmarked}
        isOwner={isOwner}
        aiToolHash={aiToolHash}
        aiToolId={aiToolId}
        aiTool={aiTool}
        isEditMode={isEditMode}
      />
      <div className="flex flex-col space-y-6 w-full h-full p-8 bg-zinc-50 dark:bg-zinc-900 overflow-y-scroll pb-40">
        {aiTool?.metadata?.template_description && (
          <div className="flex flex-col space-y-2">
            <ToolDescription
              description={
                aiTool?.metadata.template_description || "No description."
              }
              isLoadingTool={isLoadingTool}
            />
            {aiTool?.metadata?.template_tutorial && (
              <ToolTutorial
                tutorial={aiTool?.metadata.template_tutorial || "No tutorial."}
                isLoadingTool={isLoadingTool}
              />
            )}
          </div>
        )}
        <LanguageSelection
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          isLoadingTool={isLoadingTool}
        />
        {fields}
        <VarianceSelection
          selectedVariants={selectedVariants}
          setSelectedVariants={setSelectedVariants}
          isLoadingTool={isLoadingTool}
        />
        <Button
          variant="primary"
          className="flex-shrink-0"
          startIcon={
            <TbSparkles className="w-4 h-4 mr-1 fill-white dark:fill-emerald-400" />
          }
          onClick={onGenerate}
          isLoading={isLoadingOutput}
          disabled={!allFieldsFilled}
        >
          Generate
        </Button>
      </div>
    </div>
  );
}

export function ToolTutorial({
  tutorial,
  isLoadingTool,
}: {
  tutorial: string;
  isLoadingTool: boolean;
}) {
  const [showTutorial, setShowTutorial] = useState(false);

  const renderHTML = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.classList.add("hover:underline");
    });

    return { __html: doc.body.innerHTML };
  };

  if (!tutorial) return null;

  return (
    <div className="flex flex-col mt-4">
      {!showTutorial && (
        <Button
          variant="textNode"
          className="w-fit px-0 "
          textClassName="text-start text-2xs"
          onClick={() => setShowTutorial(!showTutorial)}
        >
          Need help? View tutorial
        </Button>
      )}
      {showTutorial && (
        <>
          <Label className="mb-2 mt-4">Tutorial</Label>
          {isLoadingTool ? (
            <div className="text-sm space-y-2">
              <Skeleton className="h-4 w-full bg-zinc-200/50" />
              <Skeleton className="h-4 w-3/4 bg-zinc-200/50" />
            </div>
          ) : (
            <div className="text-sm">
              <div
                className="text-zinc-900 dark:text-white"
                dangerouslySetInnerHTML={renderHTML(tutorial)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export function ToolDescription({
  description,
  isLoadingTool,
}: {
  description: string;
  isLoadingTool: boolean;
}) {
  return (
    <div className="flex flex-col">
      <Label className="mb-2">Description</Label>
      {isLoadingTool ? (
        <div className="text-sm space-y-2">
          <Skeleton className="h-4 w-full bg-zinc-200/50" />
          <Skeleton className="h-4 w-3/4 bg-zinc-200/50" />
        </div>
      ) : (
        <div className="text-sm">
          <p className="text-zinc-900 dark:text-white">{description}</p>
        </div>
      )}
    </div>
  );
}

export function VarianceSelection({
  selectedVariants,
  setSelectedVariants,
  isLoadingTool,
}: {
  selectedVariants: string;
  setSelectedVariants: (variants: string) => void;
  isLoadingTool: boolean;
}) {
  return (
    <div className="flex flex-col">
      <Label className="mb-2">Variants</Label>
      {isLoadingTool ? (
        <div className="text-sm space-y-2">
          <Skeleton className="h-4 w-full bg-zinc-200/50" />
          <Skeleton className="h-4 w-3/4 bg-zinc-200/50" />
          <Skeleton className="h-4 w-3/4 bg-zinc-200/50" />
        </div>
      ) : (
        <Select value={selectedVariants} onValueChange={setSelectedVariants}>
          <SelectTrigger>
            <SelectValue placeholder="Select a variant" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="1">1 variant</SelectItem>
            <SelectItem value="2">2 variants</SelectItem>
            <SelectItem value="3">3 variants</SelectItem>
          </SelectContent>
        </Select>
      )}
    </div>
  );
}

export function LanguageSelection({
  selectedLanguage,
  setSelectedLanguage,
  isLoadingTool,
}) {
  return (
    <div className="flex flex-col">
      <Label className="mb-2">Language</Label>
      {isLoadingTool ? (
        <div className="text-sm space-y-2">
          <Skeleton className="h-4 w-full bg-zinc-200/50" />
          <Skeleton className="h-4 w-3/4 bg-zinc-200/50" />
        </div>
      ) : (
        <LanguageSelect
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          triggerClassName={undefined}
          className={undefined}
          isLoading={isLoadingTool}
        />
      )}
    </div>
  );
}

export function ToolOutput({
  activeOutputTab,
  setActiveOutputTab,
  outputs,
  outputHistoryTotal,
  onGenerate,
  isLoadingOutput,
  aiToolHash,
  setOutputs,
  setOutputHistory,
  outputHistory,
  fieldsData,
  outputEndRef,
  selectedVotes,
  handleVote,
  loadingSpinnerRef,
}) {
  if (isLoadingOutput && outputs.length === 0) {
    return (
      <LoadingOutput
        className="mt-20"
        activeOutputTab={activeOutputTab}
        setActiveOutputTab={setActiveOutputTab}
        outputTotal={outputs.length}
        outputHistoryTotal={outputHistoryTotal}
      />
    );
  }

  if (outputs.length === 0) {
    return (
      <EmptyOutput
        activeOutputTab={activeOutputTab}
        setActiveOutputTab={setActiveOutputTab}
        outputTotal={outputs.length}
        outputHistoryTotal={outputHistoryTotal}
      />
    );
  }

  return (
    <div className="flex flex-col w-2/3">
      <AiToolOutputHeader
        activeOutputTab={activeOutputTab}
        setActiveOutputTab={setActiveOutputTab}
        outputTotal={outputs.length}
        outputHistoryTotal={outputHistoryTotal}
      />
      <div className="flex flex-col flex-1 overflow-y-auto pb-40">
        {outputs.map((output, index) => (
          <GenerationOutput
            key={index}
            outputs={outputs}
            outputHistory={outputHistory}
            generation={output.generation}
            outputWords={_.words(output.generation).length}
            aiToolId={output.id}
            aiToolHash={aiToolHash}
            generationTimestamp={output.unix_timestamp}
            setOutputs={setOutputs}
            setOutputHistory={setOutputHistory}
            promptMap={fieldsData}
            fieldsData={output.fieldsData}
            outputEndRef={index === outputs.length - 1 ? outputEndRef : null}
            selectedVotes={selectedVotes}
            handleVote={handleVote}
          />
        ))}
        {isLoadingOutput && (
          <div
            className="flex justify-center scroll-m-20"
            ref={loadingSpinnerRef}
          >
            <LoadingOutput
              activeOutputTab={activeOutputTab}
              setActiveOutputTab={setActiveOutputTab}
              outputTotal={outputs.length}
              outputHistoryTotal={outputHistoryTotal}
            />
          </div>
        )}
        {!isLoadingOutput && (
          <GenerateMoreButton
            onGenerate={onGenerate}
            isLoadingOutput={isLoadingOutput}
            allFieldsFilled={Object.values(fieldsData).every(
              (value) => value.trim() !== ""
            )}
          />
        )}
      </div>
    </div>
  );
}

export function LoadingOutput({
  className,
  loadingSpinnerRef,
}: {
  className: string;
  loadingSpinnerRef?: React.RefObject<HTMLDivElement>;
}) {
  return (
    <div
      className={cn("flex flex-col w-2/3 h-fit mt-8", className)}
      ref={loadingSpinnerRef}
    >
      <div className="flex flex-col items-center justify-center flex-1">
        <div className="relative w-full h-full mx-auto my-auto">
          <LoadSpinner
            searchResults={undefined}
            loadingText={"Frase AI is generating content..."}
          />
        </div>
      </div>
    </div>
  );
}

function EmptyOutput({
  activeOutputTab,
  setActiveOutputTab,
  outputTotal,
  outputHistoryTotal,
}) {
  return (
    <div className="flex flex-col w-2/3">
      <AiToolOutputHeader
        activeOutputTab={activeOutputTab}
        setActiveOutputTab={setActiveOutputTab}
        outputTotal={outputTotal}
        outputHistoryTotal={outputHistoryTotal}
      />
      <div className="flex flex-col items-center justify-center flex-1">
        <p className="text-center text-zinc-500 text-sm">No output</p>
      </div>
    </div>
  );
}

export function GenerationOutput({
  generation,
  outputWords,
  aiToolId,
  aiToolHash,
  generationTimestamp,
  rating,
  setOutputs,
  setOutputHistory,
  outputs,
  outputHistory,
  promptMap,
  user,
  outputEndRef,
  fieldsData,
  selectedVotes,
  handleVote,
}) {
  if (!generation) return null;
  const [expandedFields, setExpandedFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleToggleExpand = (fieldName: string) => {
    setExpandedFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  const { addNotification } = useNotificationStore();
  const updateRating = useUpdateAiToolRating();
  const deleteOutput = useDeleteAiToolOutput();
  const { createNewDocument } = useCreateNewDocumentWithUser();

  const selectedVote =
    selectedVotes?.[aiToolId] ||
    (rating === 1 ? "upvote_count" : rating === -1 ? "downvote_count" : null);
  const generationLines = generation?.split("\n") || [];

  const handleRating = (rating: number) => {
    updateRating.mutate({ id: String(aiToolId), rating: rating });
  };

  const handleCreateNewDocument = async (
    title: string,
    htmlContent: string,
    data: any
  ) => {
    await createNewDocument(title, htmlContent, data);
  };

  const handleDelete = () => {
    const previousOutputs = outputs;
    const previousOutputHistory = outputHistory;

    setOutputs((prev) => prev.filter((output) => output.id !== aiToolId));
    setOutputHistory((prev) => prev.filter((output) => output.id !== aiToolId));

    deleteOutput
      .mutateAsync({
        id: String(aiToolId),
        template_hash: aiToolHash,
      })
      .then(() => {
        addNotification({
          title: "Deleted output",
          type: "success",
        });
      })
      .catch((error) => {
        console.error("Deletion failed:", error);
        setOutputs(previousOutputs);
        setOutputHistory(previousOutputHistory);
        addNotification({
          title: "Deletion failed",
          type: "error",
        });
      });
  };

  return (
    <div
      className="flex flex-col border-b dark:border-zinc-700 px-4 justify-between space-y-4 pt-4 pb-2 scroll-m-20"
      ref={outputEndRef}
    >
      <div className="flex flex-col w-full flex-1 px-4">
        <div className="flex justify-between w-full my-2">
          {fieldsData && (
            <div className="text-xs text-zinc-500 mb-2">
              {Object.entries(fieldsData).map(([key, value]) => {
                const isExpanded = expandedFields[key] || false;
                const displayValue =
                  isExpanded || value.length <= 200
                    ? value
                    : `${value.substring(0, 200)}...`;

                return (
                  <p key={key} className="flex justify-between">
                    <span>
                      <strong className="mr-1">
                        {key[0].toUpperCase() + key.slice(1)}:
                      </strong>
                      <span>{displayValue}</span>
                      {value.length > 200 && (
                        <Button
                          variant="textTab"
                          size="2xs"
                          className="flex-shrink-0"
                          onClick={() => handleToggleExpand(key)}
                        >
                          {isExpanded ? "Show less" : "Show more"}
                        </Button>
                      )}
                    </span>
                  </p>
                );
              })}
            </div>
          )}
          <span className="text-xs text-zinc-500 flex-shrink-0 ml-8">
            {dayjs(generationTimestamp * 1000).fromNow()}
          </span>
        </div>
        {generationLines.map((line, index) => (
          <p key={index} className="text-zinc-900 dark:text-white text-sm">
            {line}
          </p>
        ))}
      </div>
      <div className="flex justify-between items-end w-full px-4 pb-4">
        <div className="flex items-center space-x-4">
          <div className="rounded-md text-2xs px-2 py-0.5 ring-1 ring-zinc-200 divide-x divide-zinc-200 dark:divide-zinc-700 dark:ring-zinc-700">
            <span className="text-zinc-500 pr-2">Words</span>
            <span className="text-zinc-500 pl-2">{outputWords}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="buttonIcon"
              buttonIcon={<TbCopy />}
              tooltipContent="Copy to clipboard"
              onClick={() => {
                navigator.clipboard.writeText(generation);
                addNotification({
                  title: "Copied to clipboard",
                  type: "success",
                });
              }}
            ></Button>
            {aiToolId && (
              <Button
                variant="buttonIcon"
                buttonIcon={<TbTrash />}
                tooltipContent="Delete output"
                onClick={() => {
                  handleDelete();
                }}
              ></Button>
            )}
            <CreateDocumentFromOutputDialog
              generation={generation}
              promptMap={promptMap}
              onCreate={handleCreateNewDocument}
            />
          </div>
        </div>
        <div>
          <Button
            variant="buttonNodeIcon"
            buttonIcon={<TbThumbUpFilled />}
            tooltipContent="Upvote"
            className={
              selectedVote === "upvote_count"
                ? "text-emerald-500"
                : "text-zinc-400 dark:text-zinc-600"
            }
            onClick={() => {
              handleVote(aiToolId, "upvote_count");
              handleRating(1);
            }}
          ></Button>
          <Button
            variant="buttonNodeIcon"
            buttonIcon={<TbThumbDownFilled />}
            tooltipContent="Downvote"
            className={
              selectedVote === "downvote_count"
                ? "text-red-500"
                : "text-zinc-400 dark:text-zinc-600"
            }
            onClick={() => {
              handleVote(aiToolId, "downvote_count");
              handleRating(-1);
            }}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export function GenerateMoreButton({
  onGenerate,
  isLoadingOutput,
  allFieldsFilled,
}) {
  return (
    <Button
      variant="outlineBlur"
      size="xs"
      className="flex-shrink-0 w-fit mx-auto mt-4"
      startIcon={<TbSparkles className="w-4 h-4 mr-1  dark:fill-emerald-400" />}
      onClick={onGenerate}
      isLoading={isLoadingOutput}
      disabled={!allFieldsFilled}
    >
      Generate more
    </Button>
  );
}

export function ToolHistory({
  activeOutputTab,
  setActiveOutputTab,
  outputHistory,
  outputTotal,
  isLoadingOutput,
  setOutputs,
  setOutputHistory,
  selectedVotes,
  handleVote,
}) {
  if (isLoadingOutput) {
    setActiveOutputTab("output");
    return (
      <LoadingOutput
        activeOutputTab={activeOutputTab}
        setActiveOutputTab={setActiveOutputTab}
        outputTotal={outputTotal}
        outputHistoryTotal={outputHistory.length}
      />
    );
  }

  if (outputHistory.length === 0) {
    return (
      <div className="flex flex-col w-2/3">
        <AiToolOutputHeader
          activeOutputTab={activeOutputTab}
          setActiveOutputTab={setActiveOutputTab}
          outputTotal={outputTotal}
          outputHistoryTotal={outputHistory.length}
        />
        <div className="flex flex-col items-center justify-center flex-1">
          <p className="text-center text-zinc-500 text-sm">No history</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-2/3">
      <AiToolOutputHeader
        activeOutputTab={activeOutputTab}
        setActiveOutputTab={setActiveOutputTab}
        outputTotal={outputTotal}
        outputHistoryTotal={outputHistory.length}
      />
      <div className="flex flex-col flex-1 overflow-y-auto pb-40">
        {outputHistory.map((output, index) => (
          <div key={index}>
            <GenerationOutput
              generation={output.generation}
              outputWords={_.words(output.generation).length}
              aiToolId={output.id}
              aiToolHash={output.template_hash}
              generationTimestamp={output.unix_timestamp}
              rating={output.rating}
              setOutputs={setOutputs}
              setOutputHistory={setOutputHistory}
              promptMap={output.prompt_map}
              fieldsData={output.prompt_map}
              selectedVotes={selectedVotes}
              handleVote={handleVote}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AiTool;
