import { Template } from "@/features/templates";
import { axios } from "@/lib/axios";
import { useQuery } from "react-query";

export const getAiToolBookmarks = (ai: Template): Promise<Template[]> => {
  return axios.post(`/getBookmarkedTemplates`, ai);
};

export const useGetAiToolBookmarks = (ai: Template) => {
  return useQuery(["getAiToolBookmarks", ai], () => getAiToolBookmarks(ai));
};
