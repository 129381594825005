import { useQuery } from "react-query";

import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

import { Template } from "../types";

export const getCommunityTemplates = async (
  user_hash?: string
): Promise<Template[]> => {
  const payload = {
    filter: "community",
    day_count: 800,
    sort: "popular",
    user_hash,
  };
  const response = await axios.post(`/getCommunityTemplates`, payload);
  return response;
};
type QueryFnType = typeof getCommunityTemplates;

type UseCommunityTemplatesOptions = {
  user_hash?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useCommunityTemplates = ({
  user_hash,
  config,
}: UseCommunityTemplatesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["communityTemplates", user_hash],
    queryFn: () => getCommunityTemplates(user_hash),
  });
};
