import React from "react";
import { TbShare } from "react-icons/tb";
import {
  Button,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { useNotificationStore } from "../../../stores/notifications";

type ShareMenuProps = {
  type: "document" | "template";
};

export const ShareMenu = ({ type }: ShareMenuProps) => {
  const { addNotification } = useNotificationStore();
  const [open, setOpen] = React.useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        addNotification({
          type: "success",
          title: `${
            type.charAt(0).toUpperCase() + type.slice(1)
          } link copied to clipboard`,
        });
        setOpen(false);
      })
      .catch(() => {
        addNotification({
          type: "error",
          title: `Failed to copy ${type} link`,
        });
        setOpen(false);
      });
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="buttonIcon" buttonIcon={<TbShare />}></Button>
            </TooltipTrigger>
            <TooltipContent>Share {type} link</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-4 space-y-2">
        <Label>Share {type.charAt(0).toUpperCase() + type.slice(1)}</Label>
        <Input value={window.location.href} readOnly autoFocus={false} />
        <Button
          onClick={handleCopyLink}
          variant="primaryBlur"
          className="w-full"
          autoFocus
        >
          Copy link to clipboard
        </Button>
      </PopoverContent>
    </Popover>
  );
};
